<template>
    <div id="firmaekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-1"
                            label="Talep Edenin Ad Soyad:"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="form.ad"
                            required
                            type="text"
                            placeholder="Adını soyadını girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            label="Tel:"
                            label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.tel"
                            required
                            type="number"
                            placeholder="Telefon numarasını girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-3"
                            label="Miktar:"
                            label-for="input-3">
                            <b-form-input
                            id="input-3"
                            v-model="form.miktar"
                            required
                            type="number"
                            placeholder="Miktar kg cinsinden girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Adres:"
                            label-for="input-4">
                            <b-form-input
                            id="input-4"
                            v-model="form.adres"
                            required
                            type="text"
                            placeholder="Adresi girin."
                            ></b-form-input>
                        </b-form-group>
                        <b-button block type="submit" variant="primary" class="float-right mb-5">Ekle</b-button>
                    </b-form>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data() {
        return{
            form: [],
        }
    },

    methods : {
        ekle(){
            var popup = Swal.fire({
                title: "İşlem Yapılıyor",
                icon: "info",
                text: "Sunucuya bağlanırken kısa bir süre beklemelisiniz.",
                showConfirmButton: false,
            });
            this.$store.dispatch("belTalepAdd", {
                bel_talep_adsoyad : this.form.ad,
                bel_talep_tel : this.form.tel,
                bel_talep_adres : this.form.adres,
                bel_talep_miktar : this.form.miktar,
                bel_talep_user : this.user.user_id,
            }).then(r => {
                popup.close();
                if(r.status){
                    this.$router.push("/bel_sayfa")
                }
            })
        }
    },

    computed: {
        user(){
            return this.$store.state.user;
        }
    }
}
</script>